export const creditCardPaymentStatuses = {
  colors: {
    error: 'danger',
    pending: 'default',
    pending_3d_secure: 'default',
    paid: 'success',
    accepted: 'success',
    refunded: 'warning',
    rejected: 'danger',
    cancelled: 'default'
  },
  statuses: [
    'error',
    'pending',
    'pending_3d_secure',
    'paid',
    'accepted',
    'refunded',
    'rejected',
    'cancelled'
  ]
}
